<template>
  <YLayoutBodyMenu :menu="[
      {title:'首页轮播',path:'/album/system/banner'},
      {title:'画册海报',path:'/album/system/poster'},
      {title:'基础配置',path:'/album/system/basic'},
  ]">
    <router-view/>
    </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>